import React from 'react';
import { ProgressBar } from 'react-bootstrap';
import Helmet from "../component/common/Helmet";
import ScrollToTop from 'react-scroll-up';
import Slider from "react-slick";
import { slideSlick } from "../page-demo/script";
import { FiCast , FiLayers , FiUsers , FiMonitor ,FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import VideoModal from "../blocks/VideoModal";
import BrandTwo from "../elements/BrandTwo";




const SlideList = [
    {
        textPosition: 'text-left',
        bgImage: 'bg_image--33',
        category: 'Kis, közép és nagyvállalatoknak',
        title: 'Kreatív, digitális megoldások',
        description: '',
        buttonText: 'Kapcsolat',
        buttonLink: 'mailto:kapcsolat@webelopment.hu'
    }   
]

const ServiceList = [
    {
        icon: <FiMonitor />,
        title: 'UX Design',
        description: 'Díjnyertes, egyedi design tervezés, tanácsadás'
    },
    {
        icon: <FiLayers />,
        title: 'Egyedi fejlesztések',
        description: 'Frontend és backend webfejlesztés'
    },
    {
        icon: <FiUsers />,
        title: 'Projektmenedzsment',
        description: 'Tapasztalt, szakértői munka a teljes projekt folyamatban'
    },
    {
        icon: <FiMonitor />,
        title: 'Alkalmazás üzemeltetés',
        description: 'Stabil működés, támogatás, utógondozás'
    },
    {
        icon: <FiMonitor />,
        title: 'Tartalomkezelés',
        description: 'Alkalmi és rendszeres tartalom feltöltés'
    },
    {
        icon: <FiMonitor />,
        title: 'Website-ok és belső rendszerek',
        description: 'Reszponzív, webes rendszerek'
    },
    {
        icon: <FiMonitor />,
        title: 'Wordpress oldalak, webáruházak',
        description: 'Fejlesztés, üzemeltetés'
    },
    {
        icon: <FiUsers />,
        title: 'Tesztelés',
        description: 'Felhasználói tesztek (UAT), automatizálás'
    },
    {
        icon: <FiLayers />,
        title: 'Banki kalkulátorok, landing site-ok',
        description: 'Sok éves banki tapasztalat'
    },
]

const InteractiveAgency = () => {
    return (
        <>
            <Helmet pageTitle="Interactive Agency" />
            {/* <Header /> */}
            <header className={`header-area formobile-menu header--transparent`}>
                <div className="header-wrapper" id="header-wrapper">
                    <div className="header-left">
                        <div className="logo" style={{fontSize:22, color: '#c6c9d8'}}>
                            Webelopment
                        </div>
                    </div>
                </div>
            </header>
            
            {/* Start Slider Area   */}
            <div className="slider-wrapper">
                <div className="slider-activation">
                {/* <Slider className="rn-slick-dot dot-light" {...slideSlick}> */}
                    {SlideList.map((value , index) => (
                        <div className={`slide slide-style-2 d-flex align-items-center justify-content-center bg_image ${value.bgImage}`} key={index} data-black-overlay="8">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className={`inner ${value.textPosition}`}>
                                            {value.category ? <span>{value.category}</span> : ''}
                                            {value.title ? <h1 className="title">{value.title}</h1> : ''}
                                            {value.description ? <p className="description">{value.description}</p> : ''}
                                            {value.buttonText ? <div className="slide-btn"><a className="rn-button-style--2 btn-solid" href={`${value.buttonLink}`}>kapcsolat@webelopment.hu</a></div> : ''}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                    {/* </Slider> */}
                </div>
            </div>
            {/* End Slider Area   */}
            
             {/* Start Service Area */}
             <div className="service-area creative-service-wrapper pb--120 bg_color--1" style={{marginTop:70}}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="section-title text-center mb--30">
                                <h2>Szolgáltatásaink</h2>
                                {/* <p>There are many variations of passages of Lorem Ipsum available, <br /> but the majority have suffered alteration.</p> */}
                            </div>
                        </div>
                    </div>
                    <div className="row creative-service">
                        {ServiceList.map( (val , i) => (
                            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                                <a className="text-center" /* href="/service-details" */ >
                                    <div className="service service__style--2">
                                        <div className="icon">
                                            {val.icon}
                                        </div>
                                        <div className="content">
                                            <h3 className="title">{val.title}</h3>
                                            <p>{val.description}</p>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            {/* End Service Area */}
            
            {/* Start Back To Top */}
            <div className="backto-top">
                <ScrollToTop showUnder={160}>
                    <FiChevronUp />
                </ScrollToTop>
            </div>
            {/* End Back To Top */}

            <Footer />
        </>
    )
}

export default InteractiveAgency
